import isPast from 'date-fns/isPast';
import addDays from 'date-fns/addDays';

const isActiveUntil = (date) => !isPast(addDays(new Date(date), 1));

const specialEvents = [
  {
    href: 'https://contenido.roll-bits.com/peso-pluma-2024p_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a-f01-ifw-nb9-hse/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/peso-pluma-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/peso-pluma-banner.jpg',
    alt: 'Peso Pluma 18 de Septiembre',
    descriptionText: 'Peso Pluma: 18 de Septiembre',
    isSlider: true,
    isActive: isActiveUntil('2024-09-18'),
  },
  {
    href: `https://contenido.roll-bits.com/metallica_20_sep_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk/?nocache=38135`,
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/metallica-circle.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/metallica-banner.jpg`,
    alt: 'Metallica 20 Septiembre',
    descriptionText: `Metallica: 20 Septiembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-09-20'),
  },
  {
    href: `https://contenido.roll-bits.com/niall_20_sep_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb/`,
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/niall-circle.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/niall-banner.jpg`,
    alt: 'Niall Horan 20 Septiembre',
    descriptionText: `Niall Horan: 20 Septiembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-09-20'),
  },
  {
    href: `https://contenido.roll-bits.com/niall_21_sep_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug/`,
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/niall-circle-2.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/niall-banner-2.jpg`,
    alt: 'Niall Horan 21 Septiembre',
    descriptionText: `Niall Horan: 21 Septiembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-09-21'),
  },
  {
    href: `https://contenido.roll-bits.com/travis-scott-mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a-f01-ifw-nb9-hse-a9o/`,
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/travis-circle.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/travis-banner.jpg`,
    alt: 'Travis Scott 21 de Septiembre',
    descriptionText: `Travis Scott 21 de Septiembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-09-21'),
  },
  {
    href: `https://contenido.roll-bits.com/metallica_22_sep_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-kgq/`,
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/metallica-circle-2.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/metallica-banner-2.jpg`,
    alt: 'Metallica 22 Septiembre',
    descriptionText: `Metallica: 22 Septiembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-09-22'),
  },
  {
    href: `https://contenido.roll-bits.com/travis-scott-22-mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a-f01-ifw-nb9-hse-a9o-mzg/`,
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/travis-circle-2.png',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/travis-banner-2.jpg`,
    alt: 'Travis Scott 22 de Septiembre',
    descriptionText: `Travis Scott 22 de Septiembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-09-22'),
  },
  {
    href: `https://contenido.roll-bits.com/niall_23_sep_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wi3/`,
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/niall-circle-3.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/niall-banner-3.jpg`,
    alt: 'Niall Horan 23 Septiembre',
    descriptionText: `Niall Horan: 23 Septiembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-09-23'),
  },
  {
    href: `https://contenido.roll-bits.com/paulo-londra-mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a-f01-ifw-nb9-hse-a9o-mzg-cw3-ujy-2p2/`,
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/paulo-londra-circle.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/paulo-londra-banner.jpg`,
    alt: 'Paulo Londra 26 de Septiembre',
    descriptionText: `Paulo Londra: 26 de Septiembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-09-26'),
  },
  {
    href: `https://contenido.roll-bits.com/metallica_27_sep_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g/?nocache=46011`,
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/metallica-circle-3.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/metallica-banner-3.jpg`,
    alt: 'Metallica 27 Septiembre',
    descriptionText: `Metallica: 27 Septiembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-09-27'),
  },
  {
    href: 'https://contenido.roll-bits.com/cascadas-honey-xl-qln-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-85e-2lw-eb5-vh3-787-e9z-28l-7h7-oas-6g7-jmn-pic/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/cascadas-circle.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/cascadas-banner.jpg`,
    alt: 'Cascadas Honey 28 Septiembre',
    descriptionText: `Cascadas Honey: 28 Septiembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-09-28'),
  },
  {
    href: 'https://contenido.roll-bits.com/metallica_29_sep_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-yhk/?nocache=20001',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/metallica-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/metallica-banner.jpg',
    alt: 'Metallica 29 Septiembre',
    descriptionText: `Metallica: 29 Septiembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-09-29'),
  },
  {
    href: 'https://contenido.roll-bits.com/idles-01-0ct_-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-kat-1qi-zni/?nocache=4940',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/idles-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/idles-banner.jpg',
    alt: 'Idles 01 de Octubre',
    descriptionText: `Idles: 01 de Octubre`,
    isSlider: true,
    isActive: isActiveUntil('2024-10-01'),
  },
  {
    href: 'https://contenido.roll-bits.com/atarashi-gakko_-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-kat-sho/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/atarashi-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/atarashi-banner.jpg',
    alt: 'Atarashi Gakko 01 de Octubre',
    descriptionText: `Atarashii Gakko: 01 de Octubre`,
    isSlider: true,
    isActive: isActiveUntil('2024-10-01'),
  },
  {
    href: 'https://contenido.roll-bits.com/thekillers-03-oct-gdl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6/?nocache=17942',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/killers-circle.png',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/killers-banner.jpg`,
    alt: 'The Killers 03 de Octubre',
    descriptionText: 'The Killers: 03 de Octubre',
    isSlider: true,
    isActive: isActiveUntil('2024-10-03'),
  },
  {
    href: 'https://contenido.roll-bits.com/eric-clapton-3-oct-lx3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-vr7/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/clapton-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/clapton-banner.jpg',
    alt: 'Eric Clapton 03 de Octubre',
    descriptionText: 'Eric Clapton: 03 de Octubre',
    isSlider: true,
    isActive: isActiveUntil('2024-10-03'),
  },
  {
    href: 'https://contenido.roll-bits.com/idles-04-0ct_-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-kat-1qi-zni-vvs/?nocache=12546',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/idles-circle-2.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/idles-banner-2.jpg',
    alt: 'Idles 04 de Octubre',
    descriptionText: `Idles: 04 de Octubre`,
    isSlider: true,
    isActive: isActiveUntil('2024-10-04'),
  },
  {
    href: 'https://contenido.roll-bits.com/thekillers5-oct-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/killers-circle-2.png',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/killers-banner-2.jpg`,
    alt: 'The Killers 05 de Octubre',
    descriptionText: 'The Killers: 05 de Octubre',
    isSlider: true,
    isActive: isActiveUntil('2024-10-05'),
  },
  {
    href: 'https://contenido.roll-bits.com/thekillers-6-oct-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx/?nocache=42169',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/killers-circle-3.png',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/killers-banner-3.jpg`,
    alt: 'The Killers 06 de Octubre',
    descriptionText: 'The Killers: 06 de Octubre',
    isSlider: true,
    isActive: isActiveUntil('2024-10-06'),
  },
  {
    href: 'https://contenido.roll-bits.com/charles-ans-mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a-f01-ifw-nb9-hse-a9o-mzg-cw3/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/charles-ans-circle.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/charles-banner.jpg`,
    alt: 'Charles Ans 06 de Octubre',
    descriptionText: 'Charles Ans: 06 de Octubre',
    isSlider: true,
    isActive: isActiveUntil('2024-10-06'),
  },
  {
    href: 'https://contenido.roll-bits.com/the_national_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-gd3/?nocache=48190',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/The+national+(1).svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/The+national+(1).jpg`,
    alt: 'The national, The Warn on Drugs 10 de Octubre',
    descriptionText: 'The national, The Warn on Drugs: 10 de Octubre',
    isSlider: true,
    isActive: isActiveUntil('2024-10-10'),
  },
  {
    href: 'https://contenido.roll-bits.com/pulso_gnp_leon_qro_7octubre-ifa-29y/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/pulso+gnp+(1).svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/pulso+gnp+(1).jpg`,
    alt: 'Pulso GNP 12 de Octubre',
    descriptionText: 'Pulso GNP: 12 de Octubre',
    isSlider: true,
    isActive: isActiveUntil('2024-10-12'),
  },
  {
    href: 'https://contenido.roll-bits.com/dpr-xl-qln-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-85e-2lw-eb5-vh3-787-e9z-28l-7h7/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/reborn-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/reborn-banner.jpg',
    alt: 'DPR 12 de Octubre',
    descriptionText: 'DPR: 12 de Octubre',
    isSlider: true,
    isActive: isActiveUntil('2024-10-12'),
  },
  {
    href: 'https://contenido.roll-bits.com/cigaretes-after-sex-mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a-f01-ifw-nb9-hse-a9o-5tv/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/cigarettes-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/cigarettes-banner.jpg',
    alt: 'Cigarettes After Sex 15 de Octubre',
    descriptionText: 'Cigarettes After Sex: 15 de Octubre',
    isSlider: true,
    isActive: isActiveUntil('2024-10-15'),
  },
  {
    href: 'https://contenido.roll-bits.com/paty-cantu-mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a-f01-ifw-nb9-hse-a9o-mzg-cw3-ujy/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/paty-cantu-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/paty-cantu-banner.jpg',
    alt: 'Paty Cantú 19 de Octubre',
    descriptionText: 'Paty Cantú: 19 de Octubre',
    isSlider: true,
    isActive: isActiveUntil('2024-10-19'),
  },
  {
    href: 'https://contenido.roll-bits.com/los-azufres-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-85e-v5i-4uk/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/los-azufres-circle-3.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/los-azufres-banner-3.jpg',
    alt: 'Los Azufres 19 de Octubre',
    descriptionText: 'Los Azufres: 19 de Octubre',
    isSlider: true,
    isActive: isActiveUntil('2024-10-19'),
  },
  {
    href: `https://contenido.roll-bits.com/six-flags-xl-qln-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-85e-2lw-eb5-vh3-787-e9z-28l-7h7-oas-6g7-jmn/`,
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/festival-terror-circle.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/festival-terror-banner.jpg`,
    alt: 'Six Flags del Terror 26 de Octubre',
    descriptionText: `Six Flags del Terror 26 de Octubre`,
    isSlider: true,
    isActive: isActiveUntil('2024-10-26'),
  },
  {
    href: 'https://contenido.roll-bits.com/aurora31oct-2024p_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a-f01-ifw-nb9/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/aurora-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/aurora-banner.jpg',
    alt: 'Aurora 31 de Octubre',
    descriptionText: 'Aurora: 31 de Octubre',
    isSlider: true,
    isActive: isActiveUntil('2024-10-31'),
  },
  {
    href: 'https://contenido.roll-bits.com/rollando-con-lacatrina2-xl-qln-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-85e-2lw-eb5-vh3-787-e9z-28l-pcn/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/janitzio-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/janitzio-banner.jpg',
    alt: 'De rol a Janitzio 01 de Noviembre',
    descriptionText: 'De rol a Janitzio: 01 de Noviembre',
    isSlider: true,
    isActive: isActiveUntil('2024-11-01'),
  },
  {
    href: 'https://contenido.roll-bits.com/slipknot-8-nov_-rev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-kat-1qi-zni-vvs-bd0-d49/?nocache=43087',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/slipknot-circle.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/slipknot-banner.jpg`,
    alt: 'Slipknot 08 de Noviembre',
    descriptionText: `Slipknot: 08 de Noviembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-11-08'),
  },
  {
    href: 'https://contenido.roll-bits.com/slipknot-9-nov_-rev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-kat-1qi-zni-vvs-bd0/?nocache=39903',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/slipknot-circle-2.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/slipknot-banner-2.jpg`,
    alt: 'Slipknot 09 de Noviembre',
    descriptionText: `Slipknot: 09 de Noviembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-11-09'),
  },
  {
    href: 'https://contenido.roll-bits.com/paulmccartney-12nov2024p_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/paul-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/paul-banner.jpg',
    alt: 'Paul McCartney 12 de Noviembre',
    descriptionText: `Paul McCartney: 12 de Noviembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-11-12'),
  },
  {
    href: 'https://contenido.roll-bits.com/paulmccartney-14nov2024p_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/paul-circle-2.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/paul-banner-2.jpg',
    alt: 'Paul McCartney 14 de Noviembre',
    descriptionText: 'Paul McCartney: 14 de Noviembre',
    isSlider: true,
    isActive: isActiveUntil('2024-11-14'),
  },
  {
    href: `https://contenido.roll-bits.com/iron_maiden-qln-x3f-926-oi9-8ap-jpa-hfw-85u-kg1-605-b3u-lqd-zdg-36q-hqq-0tg/?nocache=66700`,
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/iron-maiden-circle.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/iron-maiden-banner.jpg`,
    alt: 'Iron Maiden 20 Noviembre',
    descriptionText: `Iron Maiden: 20 Noviembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-11-20'),
  },
  {
    href: 'https://contenido.roll-bits.com/tecate-comuna-xl-qln-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-85e-2lw-eb5-vh3-787-e9z-28l/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/tecate-comuna-circle.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/tecate-comuna-banner.jpg`,
    alt: 'Tecate Comuna 23 de Noviembre',
    descriptionText: `Tecate Comuna: 23 de Noviembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-11-23'),
  },
  {
    href: 'https://contenido.roll-bits.com/flow-fest-qln-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-85e-2lw-eb5-vh3-0w1/?nocache=53013',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/flowfest-circle.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/flowfest-banner.jpg`,
    alt: 'Flow Fest 23 y 24 de Noviembre',
    descriptionText: `Flow Fest 23 y 24 de Noviembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-11-23'),
  },
  {
    href: 'https://contenido.roll-bits.com/kenia-os-29nov2024p_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a-f01/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/kenia-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/kenia-banner.jpg',
    alt: 'Kenia 29 de Noviembre',
    descriptionText: `Kenia: 29 de Noviembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-11-29'),
  },
  {
    href: 'https://contenido.roll-bits.com/kenia-os-30nov2024p_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a-f01-ifw/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/kenia-circle-2.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/kenia-banner-2.jpg',
    alt: 'Kenia 30 de Noviembre',
    descriptionText: `Kenia: 30 de Noviembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-11-30'),
  },
  {
    href: 'https://contenido.roll-bits.com/morat_02_dic_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec/?nocache=17881',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-circle.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-banner.jpg`,
    alt: 'Morat 02 Diciembre',
    descriptionText: `Morat: 02 Diciembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-12-02'),
  },
  {
    href: 'https://contenido.roll-bits.com/morat_03_dic_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm/?nocache=34006',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-circle-2.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-banner-2.jpg`,
    alt: 'Morat 03 Diciembre',
    descriptionText: `Morat: 03 Diciembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-12-03'),
  },
  {
    href: 'https://contenido.roll-bits.com/epica-y-orquesta_dic_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-zq2/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/epica-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/epica-banner.jpg',
    alt: 'Epica y Orquesta 06 de Diciembre',
    descriptionText: 'Epica y Orquesta: 06 de Diciembre',
    isSlider: true,
    isActive: isActiveUntil('2024-12-06'),
  },
  {
    href: 'https://contenido.roll-bits.com/epica-y-orquesta_7-dic_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-zq2-9kn/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/epica-circle-2.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/epica-banner-2.jpg',
    alt: 'Epica y Orquesta 07 de Diciembre',
    descriptionText: 'Epica y Orquesta: 07 de Diciembre',
    isSlider: true,
    isActive: isActiveUntil('2024-12-07'),
  },
  {
    href: 'https://contenido.roll-bits.com/morat_13_dic_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2/?nocache=21741',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-circle-3.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-banner-3.jpg`,
    alt: 'Morat 13 Diciembre',
    descriptionText: `Morat: 13 Diciembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-12-13'),
  },
  {
    href: 'https://contenido.roll-bits.com/morat_14_dic_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k/?nocache=85120',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-circle-4.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-banner-4.jpg`,
    alt: 'Morat 14 Diciembre',
    descriptionText: `Morat: 14 Diciembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-12-14'),
  },
  {
    href: 'https://contenido.roll-bits.com/twenty-one-pilots-20-feb_-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-kat/?nocache=79274',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/twenty-one-pilots-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/twenty-one-pilots-banner.jpg',
    alt: 'Twenty One Pilots 20 de Febrero',
    descriptionText: `Twenty One Pilots: 20 de Febrero`,
    isSlider: true,
    isActive: isActiveUntil('2025-02-20'),
  },
  {
    href: 'https://contenido.roll-bits.com/twenty-one-pilots-22-feb_-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-kat-1qi/?nocache=17310',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/twenty-one-pilots-circle-2.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/twenty-one-pilots-banner-2.jpg',
    alt: 'Twenty One Pilots 22 de Febrero',
    descriptionText: `Twenty One Pilots: 22 de Febrero`,
    isSlider: true,
    isActive: isActiveUntil('2025-02-22'),
  },
];

export default specialEvents;
